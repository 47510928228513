/* eslint-disable no-warning-comments */

import './src/styles/globals.scss'

export const onRouteUpdate = ({location, prevLocation}) => {
  // https://developers.hubspot.com/docs/api/events/tracking-code#tracking-in-single-page-applications
  if (process.env.NODE_ENV === 'production' && prevLocation && window._hsq) {
    const path = location ? `${location.pathname}${location.search}${location.hash}` : undefined
    window._hsq.push(['setPath', path])
    window._hsq.push(['trackPageView'])
  }
}
